var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_vm.loading ? _c('div', [_c('Spinner')], 1) : _c('div', [_vm.results.error ? _c('div', [_c('div', {
    staticClass: "alert alert-danger"
  }, [_vm._v(_vm._s(_vm.results.message))]), Array.isArray(_vm.results.data) ? _c('table', {
    staticClass: "table"
  }, [_vm._m(0), _c('tbody', _vm._l(_vm.results.data.filter(function (r) {
    return r.errors.length > 0;
  }), function (error) {
    return _c('tr', {
      key: error.row
    }, [_c('td', {
      staticClass: "font-w600"
    }, [_vm._v(_vm._s(error.row))]), _c('td', {
      staticClass: "text-danger"
    }, [_vm._v(_vm._s(error.errors.join(', ')))])]);
  }), 0)]) : _vm._e()]) : _vm.alreadyImported ? _c('div', [_c('div', {
    staticClass: "alert alert-warning"
  }, [_vm._v("This file has already been imported.")])]) : _c('div', [_vm.results.error === false ? _c('div', {
    staticClass: "alert alert-success"
  }, [_vm._v(" " + _vm._s(_vm.results.message) + " ")]) : _vm._e(), Array.isArray(_vm.results.data) ? _c('table', {
    staticClass: "table"
  }, [_c('thead', [_c('tr', [_c('th', [_vm._v("Row")]), _vm.primaryField ? _c('th', [_vm._v(_vm._s(_vm.primaryField.friendly))]) : _vm._e(), _c('th', [_vm._v("Imported")])])]), _c('tbody', _vm._l(_vm.results.data, function (item, index) {
    return _c('tr', {
      key: item._id
    }, [_c('td', {
      staticClass: "font-w600"
    }, [_vm._v(_vm._s(index + 1))]), _vm.primaryField ? _c('td', [_vm._v(_vm._s(item[_vm.primaryField.key]))]) : _vm._e(), _vm._m(1, true)]);
  }), 0)]) : _c('div', [_c('div')])]), _c('div', {
    staticClass: "d-flex justify-content-between mb-3"
  }, [_c('router-link', {
    staticClass: "btn btn-light",
    attrs: {
      "to": {
        name: 'import-list'
      }
    }
  }, [_vm._v("Start Over")]), _c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'import-detail',
        params: {
          id: _vm.importItem._id
        }
      }
    }
  }, [_vm._v("View Import")])], 1)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("Row")]), _c('th', [_vm._v("Message")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('i', {
    staticClass: "fad fa-circle-check fa-lg text-success"
  })]);

}]

export { render, staticRenderFns }